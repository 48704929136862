@import "../abstract/variables";

@import "../abstract/variables";

.btn {
  font-family: $otito_font_semi_bold;
  border-radius: 0.1rem;
  font-size: 1.6rem;
  text-align: center;
  justify-content: center;
  padding: 1rem;
  border: none;
}

.btn-black {
  background-color: $black-500;
  color: #fff;
  display: flex;
  align-items: center;
  line-height: 0;
  transition: all ease 0.3s;
  position: relative;
  transition: all 0.5s ease;
  &:hover,
  &:focus {
    color: #fff;
    background-color: $black-500;
    &::after {
      left: 0;
      top: 0;
    }
  }
  &:disabled {
    color: #fff;
    background-color: $grey-40;
  }
}

.btn-lg {
  min-height: 4.8rem;
  min-width: 10rem;
  max-width: 100%;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  font-weight: 700;
}

.btn-md {
  min-height: 3.8rem;
  min-width: 8rem;
  font-size: 1.6rem;
}

.btn-sm {
  height: 3rem;
  min-width: 0;
  font-size: 1.3rem;
  min-height: 0;
  display: flex;
  align-items: center;
}

.previous-btn {
  gap: 2rem;
}
