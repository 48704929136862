.notFound_page {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  img {
    width: 30rem;
    object-fit: contain;
  }
  p {
    font-weight: 500;
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
}
