@import "./abstract/variables";
@import "./base/buttons";
@import "./base/form";
@import "./base/typography";
@import "./abstract/mixin.scss";
@import "./base/form";

html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  @media (max-width: 900px) {
    font-size: 59.5%;
  }
  @media (max-width: 700px) {
    font-size: 55.5%;
  }
}

html::-webkit-scrollbar {
  width: 0.8rem;
}

html::-webkit-scrollbar-track {
  background: transparent;
}
html::-webkit-scrollbar-thumb {
  background: #9d9d9d;
  border-radius: 5rem;
}

*,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
}
body {
  font-family: $otito_font_regular;
  color: $grey-300;
}

.container {
  padding-inline: var(--bs-gutter-x, 2rem);
  width: 100%;
  margin-inline: auto;
  @media (max-width: 1000px) {
    padding-inline: 1rem;
  }
  @media (max-width: 600px) {
    padding-inline: var(--bs-gutter-x, 2rem);
  }
  @media (min-width: 1060px) {
    max-width: 1000px;
  }
  @media (min-width: 1100px) {
    max-width: 1089px;
  }
  @media (min-width: 1200px) {
    max-width: 1189px;
  }
  @media (min-width: 1400px) {
    max-width: 1289px;
  }
  @media (min-width: 1700px) {
    max-width: 1500px;
  }
  @media (min-width: 1800px) {
    max-width: 1600px;
  }
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(171px, 1fr));
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
}

.table {
  tr {
    th {
      background-color: rgba($color: $grey-100, $alpha: 0.2);
      font-size: 1.2rem;
      padding: 1rem 2rem;
      border: none;
    }

    td {
      padding: 2rem;
      font-size: 1.4rem;
    }
  }
}

.badge {
  border-radius: 3rem;
  font-size: 1rem;
  font-family: $otito_font_semi_bold;
  font-weight: 600;
  &.bg-success, &.completed, &.delivered {
    background-color: rgba($color: $success, $alpha: 0.1) !important;
    color: $success;
  }
  &.shipped {
    background-color: rgba($color: $info, $alpha: 0.1) !important;
    color: $info;
  }
  &.bg-warning, &.processing {
    background-color: rgba($color: $warning, $alpha: 0.1) !important;
    color: $warning;
  }
  &.bg-error, &.cancelled, &.canceled {
    background-color: rgba($color: $error, $alpha: 0.1) !important;
    color: $error;
  }
}

.show > .btn-primary.dropdown-toggle {
  background-color: inherit;
  color: inherit;
}
.dropdown-toggle {
  display: flex;
  align-items: center;
  &::after {
    border: 0;
    line-height: 0;
    content: url("https://api.iconify.design/pepicons/angle-down.svg");
  }
  &.no-toggle {
    &:focus {
      box-shadow: none !important;
    }
    &::after {
      display: none;
    }
  }
}
.file-picker {
  // margin-top: 1rem;
  width: 8rem;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1rem dashed $line-color-100;
  background: $white-000;
  cursor: pointer;
  font-size: 1.6rem;
}
.selected-option {
  background-color: $white-000;
  min-width: 4.5rem;
  padding: 0.5rem 1rem;
  position: relative;
  font-size: 1.2rem;
  font-family: $otito_font_medium;
  .remove {
    position: absolute;
    top: 0;
    right: -0.4rem;
    cursor: pointer;
    font-size: 1.5rem;
    color: $black-500;
  }
}

.modal-content {
  padding: 3rem;
  .modal-header {
    border-bottom: none;
  }
}
.react-skeleton-load {
  width: 100% !important;
}
.react-select {
  .css-q8tyf7-control {
    font-family: $otito_font_semi_bold;
  }
}
.w-fit{
  width: fit-content !important;
}

.max-td{
  max-width: 30rem;
  white-space: break-spaces;
}