// Import your fonts here
@font-face {
  font-family: "sharp-sans-light";
  src: url("../../../assets/fonts/sharp-sans/Sharp\ Sans\ Light.otf");
}
@font-face {
  font-family: "sharp-sans";
  src: url("../../../assets/fonts/sharp-sans/Sharp\ Sans.otf");
}
@font-face {
  font-family: "sharp-sans-medium";
  src: url("../../../assets/fonts/sharp-sans/Sharp\ Sans\ Medium.otf");
}
@font-face {
  font-family: "sharp-sans-semi-bold";
  src: url("../../../assets/fonts/sharp-sans/Sharp\ Sans\ Semibold.otf");
}
@font-face {
  font-family: "sharp-sans-bold";
  src: url("../../../assets/fonts/sharp-sans/SharpSansBold.otf");
}
@font-face {
  font-family: "sharp-sans-extra-bold";
  src: url("../../../assets/fonts/sharp-sans/Sharp\ Sans\ Extrabold.otf");
}
// font
$otito_font_light: "sharp-sans-light", sans-serif;
$otito_font_regular: "sharp-sans", sans-serif;
$otito_font_medium: "sharp-sans-medium", sans-serif;
$otito_font_semi_bold: "sharp-sans-semi-bold", sans-serif;
$otito_font_bold: "sharp-sans-bold", sans-serif;
$otito_font_extra_bold: "sharp-sans-extra-bold", sans-serif;

// Alerts
$success: #029b1f;
$warning: #b78a26;
$error: #b94931;
$info: #418ed9;
$tip: #4d4d4d;

//just colors
$blue: #3120f9;
$purple: #a21fe0;

// Primary colors
$primary-50: #f9f5ff;
$primary-100: #ebd4f9;
$primary-200: #c48ee7;
$primary-300: #ab5edb;
$primary-400: #912fcf;
$primary-500: #7700c3;

// Secondary Colours
$black-000: #000000;
$black-100: #595959;
$black-200: #3b3b3b;
$black-300: #1e1e1e;
$black-500: #050404;

// Neutrals & Shades
$white-000: #ffffff;
$line-color-100: #d9d9d9;
$line-color-200: #d1d1d1;
$grey-10: #d9d9d9;
$grey-20: #f9f9f9;
$grey-30: #f6f6f6;
$grey-40: #c0c0c0;
$grey-100: #858585;
$grey-300: #595959;

$theme-colors: (
  "success": $success,
  "warning": $warning,
  "error": $error,
  "info": $info,
  "tip": $tip,

  "blue": $blue,
  "purple": $purple,

  "black-000": $black-000,
  "black-100": $black-100,
  "black-200": $black-200,
  "black-300": $black-300,
  "black-500": $black-500,

  "white-000": $white-000,
  "line-color-100": $line-color-100,
  "line-color-200": $line-color-200,
  "grey-10": $grey-10,
  "grey-20": $grey-20,
  "grey-30": $grey-30,
  "grey-40": $grey-40,
  "grey-100": $grey-100,
  "grey-300": $grey-300,

  "primary-50": $primary-50,
  "primary-100": $primary-100,
  "primary-200": $primary-200,
  "primary-300": $primary-300,
  "primary-400": $primary-400,
  "primary-500": $primary-500,
);

$spacers: (
  "4_5": 2rem,
  "5_5": 3.6rem,
  6: 6rem,
  7: 7rem,
  8: 8rem,
  9: 9rem,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1350px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1300px,
);

$side_bar_width: 20.6rem;
