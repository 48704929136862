@import "assets/styles/abstract/variables";

.loader-component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .loader-title {
    color: #96141c;
    font-weight: 800;
    font-family: $otito_font_bold;
    font-size: 0.9em;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin: 0;
  }

  .loading span {
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    margin: 10px 3px;
    background: #007db6;
    border-radius: 0.6em;
    animation: loading 1s infinite alternate;
  }

  .loading span:nth-of-type(2) {
    background: #008fb2;
    animation-delay: 0.2s;
  }

  .loading span:nth-of-type(3) {
    background: #009b9e;
    animation-delay: 0.4s;
  }

  .loading span:nth-of-type(4) {
    background: #00a77d;
    animation-delay: 0.6s;
  }

  .loading span:nth-of-type(5) {
    background: #00b247;
    animation-delay: 0.8s;
  }

  .loading span:nth-of-type(6) {
    background: #5ab027;
    animation-delay: 1s;
  }

  .loading span:nth-of-type(7) {
    background: #a0b61e;
    animation-delay: 1.2s;
  }

  @keyframes loading {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
