@import "assets/styles/abstract/variables";

.layout-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .content-wrapper {
    padding-block: 3rem;
    @media (min-width: 992px) {
      width: calc(100% - #{$side_bar_width});
      margin-left: auto;
      padding: 3rem 2rem;
    }
  }
}
