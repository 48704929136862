@import "assets/styles/abstract/mixin";
@import "assets/styles/abstract/variables";

.menu-icon {
  position: absolute;
  left: 2.2rem;
  top: 1.9rem;
  width: 2rem;
  font-size: 2.8rem;
  display: none;
  z-index: 1;
  @media (max-width: 992px) {
    display: block;
  }
}

.logo {
  padding: 1.5rem 2rem 5rem;
}

.closeMenu {
  display: none;
  padding: 0.5rem 1rem;
  color: $white-000;
  cursor: pointer;
  font-size: 3rem;
  position: absolute;
  right: 0;
  opacity: 0.5;
}

.sidebar {
  transition: 0.3s all ease-in-out;
  overflow-y: scroll;
  z-index: 3;
  position: fixed;
  bottom: 0;
  min-height: 100vh;
  width: 0% !important;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 992px) {
    width: $side_bar_width !important;
    display: block !important;
  }
  @media (max-width: 992px) {
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background-color: rgba($black-100, 0.8);
      &.overlay-isOpen {
        width: 100%;
        z-index: 3;
        .closeMenu {
          display: block;
        }
      }
    }
    &-isOpen {
      width: 40% !important;
    }
  }
  @media (max-width: 600px) {
    &-isOpen {
      width: 80% !important;
    }
  }
  &-wrapper {
    padding: 2rem 0;
    min-height: 100vh;
    position: absolute;
    background-color: $black-500;
    // border-right: solid 0.1rem #f4efe3;
    width: 100%;
    @media (min-width: 992px) {
      padding: 1rem 0rem 2rem 0rem;
    }
    .top-list {
      min-height: calc(100vh - 24rem);
    }
    @media (max-width: 992px) {
      padding: 7rem 0;
      .top-list {
        min-height: calc(100vh - 34rem);
      }
    }
    ul {
      padding-left: 0;
      li {
        list-style: none;
        .side-link {
          font-size: 1.4rem;
          padding: 1.3rem 2.3rem;
          cursor: pointer;
          margin: 2.5rem 0;
          display: flex;
          color: $white-000;
          opacity: 0.8;
          align-items: center;
          text-decoration: none;
          transition: all 0.3s ease;
          span {
            line-height: 0;
          }
          .side-icon {
            margin-right: 2.7rem !important;
            color: $white-000;
            font-size: 2rem;
          }
          &:hover {
            opacity: 1;
            border-left: solid 0.4rem $primary-500;
          }
          &.active {
            opacity: 1;
            background-color: rgba($primary-500, 0.1);
            border-left: solid 0.4rem $primary-500;
            .side-icon {
              color: $primary-500;
            }
          }
        }

        .sub-links {
          padding-left: 5rem;
          display: none;
          transition: height 0.5s;
          ul {
            li {
              list-style: disc;
            }
          }
          .side-link {
            padding: 0.7rem 2.3rem;
            opacity: 0.5;
            &:hover,
            &.active {
              border: none;
              opacity: 1;
            }
          }
          &.show {
            display: block;
          }
        }
      }
    }
  }
}
