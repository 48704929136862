@import "assets/styles/abstract/variables";

.nav {
  @media (max-width: 767px) {
    justify-content: flex-end !important;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .fw-semi-bold {
      margin-left: 10px;
    }
  }
  .nav-search {
    width: 33rem;
  }
  .user-profile-wrapper {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    img {
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
    }
  }

  .fw-custom-dp {
    .customhover {
      .unread {
        background-color: transparent;
      }
    }
  }

  .customhover {
    &:hover {
      background-color: unset;
    }

    &:nth-child(1) {
      cursor: auto;
    }

    .order-wrapper {
      padding: 3px;
      margin-top: 5px;

      background-color: rgba($color: #000000, $alpha: 0.1);

      &:nth-child(4) {
        border-bottom: none !important;
      }

      &:first-child {
        margin-top: 0;
      }

      .gap-custom {
        gap: 30px;
      }

      .custom-dot {
        position: relative;

        &::after {
          position: absolute;
          content: "";
          width: 7px;
          height: 7px;
          margin-left: 4px;
          border-radius: 50%;
          background-color: #7700c3;
          transform: translateY(-50%);
          top: 50%;
        }
      }

      .truncate {
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.text-md-custom {
  font-size: 16px;
}

.link-wrapper {
  margin: 20px 0 0;
  display: flex;
  justify-content: center;

  button {
    padding: 10px 20px;
    max-width: 150px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    background-color: $primary-500;
    border-radius: 5px;
    border: 2px solid $primary-500;
    color: $white-000;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    transition: 0.3s linear;

    &:hover {
      background-color: transparent;
      color: $primary-500;
    }
  }
}

.dot {
  display: flex;

  &::before {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    background-color: $primary-500;
    top: 8px;
    right: 8px;
    border-radius: 5px;
  }
}
